/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledCol = styled(motion.div)`
  &:first-of-type {
    padding: 25px 25px 25px 15px;
  }

  &:last-of-type {
    padding: 25px 15px 25px 25px;
  }

  padding: 25px 25px 25px 25px;

  & a {
    color: ${(props) => props.theme.color.text.main};

    &:hover {
      color: ${(props) => props.theme.color.text.main};
    }
  }

  @media screen and (max-width: 1200px) {
    width: 194px;

    &:first-of-type {
      padding: 25px 25px 25px 25px;
    }

    &:last-of-type {
      padding: 25px 25px 25px 25px;
    }
  }

  @media screen and (max-width: 991px) {
    width: unset;

    &:first-of-type {
      padding: 25px 25px 25px 25px;
    }

    &:last-of-type {
      padding: 25px 25px 25px 25px;
    }
  }

  @media screen and (max-width: 768px) {
    width: unset;
    padding: 15px 15px 15px 15px !important;
  }
`

interface ApplicationsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ApplicationsSmall
}

const ApplicationsSmall: React.FC<ApplicationsProps> = ({ fields }) => (
  <div className="py-5">
    <div className="container py-5">
      <ParseContent content={fields.description} />
      <div className="row pt-5">
        {fields?.applications?.map((application: any, index: number) => (
          <StyledCol
            initial="init"
            whileHover="hover"
            className={`col-lg ${index <= 2 ? `col-sm-4` : `col-sm-6`} ${
              index <= 3 ? `col-6` : `col-12`
            }`}
            key={index}
          >
            <Link to={application.link.url}>
              <Plaatjie image={application.image} alt="" />
            </Link>
          </StyledCol>
        ))}
      </div>
    </div>
  </div>
)

export default ApplicationsSmall
